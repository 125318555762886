import { Outlet } from "react-router-dom";
import Header from "./Header"
import SidePanel from "./SidePanel"
import Footer from "./Footer"
import MessageModal from "./MessageModal";
import NoteModal from "./NoteModal";



// Use a shared layout
export default function Layout() {
    return (
        <>
            <Header />
            <Outlet />
            <MessageModal />
            <NoteModal />
            <SidePanel  />
            <Footer />
        </>

    )
}