import { useEffect, useRef, useState } from "react";
import { useAudioManager } from "../../hooks/audioManager-hook";
import ProgressBar from "./ProgressBar";
import VolumeControls from "./VolumeControls";
import "./storyPlayer.css";


export default function StoryPlayer() {
  const [isPlaying, setIsPlaying] = useState(null)
  const { storyPlayerTrack,
    muted, setMuted,
    gain, setGain,
    elapsed, duration,
    getSeekTime,
    setFocused,
    focused,
    isFocusTrack } = useAudioManager()


  useEffect(() => {
    if (storyPlayerTrack?.isPlaying) {
      fadeInInterface();
    } else if (storyPlayerTrack === null) {
      hide()
    }
  }, [storyPlayerTrack]);


  // toggle button from player
  useEffect(() => {

    if (isPlaying != null && storyPlayerTrack) {
      const button = document.querySelector(`[data-path="${storyPlayerTrack?.path}"][data-type=${storyPlayerTrack?.type}]`)
      button.querySelector('i').click()
    }
  }, [isPlaying])

  function hide() {
    const iface = document.querySelector(".audio-interface");
    iface.classList.remove("interface-fadein");
    iface.classList.remove("interface-fadeout");
    iface.style = "pointer-events:none";
  }

  function fadeInInterface() {
    const iface = document.querySelector(".audio-interface");
    iface.classList.add("interface-fadein");
    iface.classList.remove("interface-fadeout");
    iface.style = "pointer-events:auto";
  }

  return (
    <div className="row audio-interface p-2 align-items-center justify-content-evenly" style={{ pointerEvents: "none" }}>

      <div className="col-2">
        <i onClick={() => setIsPlaying(!isPlaying)} className={"audioPlay-Btn master-play " + (storyPlayerTrack?.isPlaying
          ? "bi bi-pause-circle-fill"
          : "bi bi-play-circle-fill")
        }>
        </i>
      </div>

      <div className="text-center col-6 pb-3">
        <div className="track-title ">{storyPlayerTrack?.trackName}</div>
        <ProgressBar elapsed={elapsed} duration={duration} seekCallback={getSeekTime} />
      </div>

      <div className="col-2" id="volume-block">
        <div className="d-flex flex-row-reverse">
          <div className="d-none d-sm-block">
            <VolumeControls setMuted={setMuted} muted={muted} setVolume={setGain} volume={gain} />
          </div>
          {isFocusTrack
            && <button id="focus-btn" className={focused ? "focus-btn active" : "focus-btn"} onClick={() => setFocused(!focused)}>
              Focus
            </button>}
        </div>
      </div>
    </div>
  );
}

