import React, { createContext, useContext, useEffect, useState } from "react";
import StoryConfig from "../config/storyConfig.json"
import { Modal } from "bootstrap";


const StoryStateContext = createContext();
export const useStoryState = () => useContext(StoryStateContext);

export default function StoryStateProvider({ children }) {
    const [unlockedTracks, setUnlockedTracks] = useState([...StoryConfig.defaultUnlocks]);
    const [unlockedActs, setUnlockedActs] = useState([...StoryConfig.info.acts.map(_ => [])])
    const [storyState, setStoryState] = useState(null)
    const [html, setHTML] = useState(null)
    const [trackNotifications, setTrackNotifications] = useState(JSON.parse(localStorage.getItem('tracknotifications')) || [])


    function addNotification(trackName) {
        if (!trackNotifications.includes(trackName)) {
            setTrackNotifications(prevState => [...prevState, trackName])
        }
    }

    useEffect(() => {
        localStorage.setItem('tracknotifications', JSON.stringify(trackNotifications))
    }, [trackNotifications])

    function fetchStoryState() {
        // get story state
        fetch("/getStoryState", { method: "POST" })
            .then(res => res.json()).then(setStoryState)
    }

    // fetch story state
    useEffect(() => { fetchStoryState() }, [])

    // when storystate has been updated
    useEffect(() => {
        if (storyState) {
            setUnlockedTracks(storyState.tracks)
            setUnlockedActs(storyState.acts)
        }
    }, [storyState])



    function unlockTrack(trackName) {

        // if track is not in the array
        if (!unlockedTracks.includes(trackName)) {

            // update state with server
            fetch(`/updateTrackState/${trackName}`, {
                method: "POST",
            })
                .then(res => res.json())
                .then(({ unlocked }) => {
                    unlocked && fetchStoryState()
                })
        }
    }

    function createBookmark(popoverRef) {
        const { act, scene, section } = popoverRef
        fetch('/createHighlight', {
            method: "POST",
            body: JSON.stringify({ act, scene, section, datetime: new Date().toLocaleString() }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(fetchStoryState)
    }


    function showNoteModal(popoverRef) {

        // open note modal
        const msgModal = document.getElementById('note-modal')
        const myModal = new Modal(msgModal)

        msgModal.dataset.popoverRef = JSON.stringify({ ...popoverRef, ref: null })
        myModal.show()
    }

    function createNote(noteText, popoverRef) {
        const { act, scene, section } = popoverRef

        fetch('/createHighlight', {
            method: "POST",
            body: JSON.stringify({ noteText, act, scene, section, datetime: new Date().toLocaleString() }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(fetchStoryState)
    }


    function editNote(popoverRef) {
        const { id, noteText, act, scene, section, datetime } = popoverRef
        fetch('/editHighlight', {
            method: "POST",
            body: JSON.stringify({ id, noteText, act, scene, section, datetime }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(fetchStoryState)
    }

    function deleteHighlight(ids) {
        fetch('/deleteHighlight', {
            method: "POST",
            body: JSON.stringify({ ids }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(fetchStoryState)
    }


    function fetchMarkup(act, scene) {
        fetch(`${process.env.PUBLIC_URL}/storyPages/Act_${act}/${scene}.html`)
            .then(res => res.text())
            .then(setHTML)
            .then(fetchStoryState)
    }

    function getHTML(act, scene) {
        fetch(`/getScene/${act}/${scene}`, {
            method: "POST",
        }).then(res => res.json())
            .then(json => {
                if (json.status) {
                    fetchMarkup(act, scene)
                }
            })
    }

    // state and functions available to any component 
    // wrapped in the AudioContext.Provider.
    return (
        <StoryStateContext.Provider value={{
            unlockedTracks,
            unlockTrack,
            setUnlockedTracks,
            unlockedActs,
            setUnlockedActs,
            createNote,
            showNoteModal,
            createBookmark,
            deleteHighlight,
            editNote,
            storyState,
            setStoryState,
            fetchStoryState,
            trackNotifications,
            setTrackNotifications,
            html,
            getHTML,
            addNotification
        }}>
            {children}
        </StoryStateContext.Provider>
    );
}
