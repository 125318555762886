
import { useEffect, useRef, useState } from "react"
import { Modal } from "bootstrap";

// hooks
import { useStoryState } from "../../hooks/storyState-hook";
import { useLoggedIn } from "../../hooks/loggedin-hook"
import { useNavigate, useParams } from "react-router-dom";
import hint from "../../icons/bookmarks_and_notes_v.png"

import EditModal from "../common/EditModal";
import Pagination from "../common/Pagination";
import "./highlight.css"


export default function Notes() {
    const { storyState } = useStoryState();
    const { loggedIn } = useLoggedIn()
    const { index } = useParams()
    const [sceneData, setSceneData] = useState(null)
    const [closeEditModal, setCloseEditModal] = useState(null)
    const navigate = useNavigate();
    const msgModalRef = useRef(null)

    useEffect(() => {
        const [...titles] = document.querySelectorAll('.note-title')
        if (titles.length > 0) {
            const result = titles.find(elem => elem.textContent === index)
            if (result) {
                result.scrollIntoView({ block: "center", behavior: "instant" });
            }
            else {
                window.scrollTo(0, 0)
            }
        }
    }, [storyState?.notes])



    useEffect(() => {
        if (closeEditModal) {
            msgModalRef.current.hide()
            setCloseEditModal(null)
        }
    }, [closeEditModal])

    function editNote(noteID) {

        // open note modal
        const modalElem = document.getElementById('edit-modal')
        const textArea = modalElem.querySelector('textarea')

        // populate text with saved note
        const noteToEdit = storyState.notes.find(note => note.id === noteID)
        textArea.value = noteToEdit.noteText

        // update note modal with sceneData for selected note
        const { id, act, scene, section } = noteToEdit
        setSceneData({ id, act, scene, section, noteText: noteToEdit.noteText })

        // show modal
        msgModalRef.current = new Modal(modalElem)
        msgModalRef.current.show()
    }

    function formatDate(datetime) {
        const options = {
            year: 'numeric',
            month: 'short', day: 'numeric'
        };

        const date = new Date(datetime);
        return date.toLocaleDateString("en-US", options)
    }

    const renderedNotes = storyState?.notes?.toReversed().map(note => (
        <div className="mb-5 note">

            <div className="list-group-item note-heading">

                <div className="d-flex justify-content-between align-items-center mb-2">

                    <div className="d-flex">
                        <div>{formatDate(note.datetime)} - </div>
                        &nbsp;
                        <div className="note-title">{note.act}.{note.scene}.{note.section}</div>

                    </div>

                    <div>
                        <button class="btn note-btn me-2"
                            onClick={() => navigate(`/story/${note.act}/${note.scene}/${note.section}`)}>
                            View
                        </button>
                        <button class="btn note-btn"
                            onClick={() => editNote(note.id)}>
                            Edit
                        </button>
                    </div>

                </div>
            </div>

            <div id="note-text" class="form-control">
                {note.noteText}
            </div>
        </div>
    ))


    return (
        <>{loggedIn?.email ?
            <section id="notes">
                <div className="content-wrap">

                    <div className="row justify-content-center">
                        <div className="col col-md-8">
                            <ul class="list-group p-2">
                                <h1 id="notes-page-title" class="display-4">Notes</h1>
                                <div className="col col-md-3">
                                    <img src={hint} alt="Bookmarks and Notes" />
                                </div>
                                <br />
                                <p>Click on a character name anywhere in the story (as shown above) to add Notes, which you can revisit from this page.</p>
                                <Pagination numResults={storyState?.notes?.length} resultsPerPage={10} children={renderedNotes} />
                            </ul>
                        </div>
                    </div>
                </div>
                <EditModal sceneData={sceneData} setClose={setCloseEditModal} ></EditModal>
            </section>
            :
            <div className="py-5 my-5">
                <div className="container py-5 my-5">
                    <section id="content">
                        <div className="content-wrap">
                            <div className="container clearfix py-5 my-5">
                                <div class="jumbotron">
                                    <h1 className="center display-5 fw-bold"> Please login to access this page.</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>}
        </>
    )
}
