import { useAudioManager } from "../../hooks/audioManager-hook";
import VolumeControls from "./VolumeControls";
import { useLocation } from "react-router-dom";

import "./backgroundPlayer.css";


export default function BackgroundPlayer() {
    const { setBackgroundGain, backgroundGain,
        setBackgroundMuted, backgroundMuted } = useAudioManager()
        const loc = useLocation();
        let color = "";
  
        switch (loc.pathname) {
    
          case "/music":
            color = "light";
            break;
          default:
            color = "dark";
        }
      

    return (
        <div className="d-flex flex-column fw-bold text-center">
        <span className="pb-1">Background Audio</span>

        <div id="background-player" className="d-flex">
            <VolumeControls setMuted={setBackgroundMuted} muted={backgroundMuted} setVolume={setBackgroundGain} volume={backgroundGain} theme={color} />
        </div>
        </div>

    );
}
