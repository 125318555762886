
import { useEffect, useState } from "react"
import { Modal } from "bootstrap"
import { useLoggedIn } from "../../hooks/loggedin-hook"
import "./myaccount.css"
import Admin from "./Admin";
var bcrypt = require('bcryptjs');


export default function MyAccount() {
    const { loggedIn, setLoggedIn } = useLoggedIn()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')


    function validatePassword(password) {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/
        const passwordInput = document.getElementById('password-input')
        const valid = passwordInput.reportValidity()
        if (passwordRegex.test(password) && valid) {
            return true
        }
        return false
    }


    function validateEmail() {
        const emailInput = document.getElementById('email-input')
        return emailInput.reportValidity()
    }

    function update() {

        const msgModal = document.getElementById('msg-modal')
        const modalBody = msgModal.querySelector('.modal-body')
        const myModal = new Modal(msgModal)
        const modalFooter = msgModal.querySelector('.modal-footer')
        modalFooter.innerHTML = ""
        modalFooter.innerHTML = '<button id="dismiss-btn" type="button" class="button button-border button-light button-rounded" data-bs-dismiss="modal">Close</button>'


        const valPass = validatePassword(password)
        const valEmail = validateEmail(email)


        if (password && email !== '') {

            if (valPass && valEmail) {

                updatePassword(password)
                    .then(json => {

                        // if password was updated
                        if (json.updated) {
                            updateEmail(email)
                                .then(res => res.json())
                                .then(json => {
                                    setEmail('')
                                    setPassword('')
                                    document.getElementById('password-input').value = ''
                                    document.getElementById('email-input').value = ''
                                    if (json.updated) {
                                        modalBody.innerText = `
                                    Password updated. Check new email for activation link.`
                                    }
                                    else {
                                        modalBody.innerText = `
                                    Password updated. If you recently requested an update using this email, check your inbox to confirm the update or try again in 5 minutes.
                                    `
                                    }
                                    myModal.show()
                                })
                        }
                        else if (json.updated === null) {
                            // show validation message
                            const passwordInput = document.getElementById('password-input')
                            passwordInput.reportValidity()
                        }
                    })
            }


        }

        else if (password !== '' && valPass) {
            updatePassword(password)
                .then(json => {
                    document.getElementById('password-input').value = ''
                    if (json.updated) {
                        modalBody.innerText = "Password updated."
                        setPassword('')
                    } else if (json.updated === false) {
                        modalBody.innerText = "Update failed, contact support."
                    }
                    else if (json.updated === null) {
                        // show validation message
                        const passwordInput = document.getElementById('password-input')
                        passwordInput.reportValidity()
                        return
                    }
                    myModal.show()
                })

        }
        else if (email !== '' && valEmail) {
            updateEmail(email)
                .then(res => res.json())
                .then(json => {
                    setEmail('')
                    document.getElementById('email-input').value = ''
                    if (json.updated) {
                        modalBody.innerText = "Please check new email to complete the update."
                    }
                    else {
                        modalBody.innerText = `If you recently requested an update using this email, check your inbox to confirm the update or try again in 5 minutes.`
                    }
                    myModal.show()
                })
        }

    }


    async function updatePassword() {

        // validate input
        const passwordInput = document.getElementById('password-input')
        passwordInput.reportValidity()

        let result = { updated: null }
        if (password !== '' && /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/.test(password)) {

            const saltRounds = 10;
            const hashed_password = await bcrypt.hash(password, saltRounds);

            //send new password to server
            result = await fetch('/settingsPassword', {
                method: "POST",
                body: JSON.stringify({ hashed_password }),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json())
        }

        return result
    }


    function updateEmail() {

        //validate input
        const emailInput = document.getElementById('email-input')
        emailInput.reportValidity()

        if (email && !emailInput.validity.typeMismatch) {
            return fetch('/updateEmail', {
                method: "POST",
                body: JSON.stringify({ email }),
                headers: { 'Content-Type': 'application/json' }
            })
        }
    }


    function deleteAccount(e) {
        e.preventDefault()

        const msgModal = document.getElementById('msg-modal')
        const modalBody = msgModal.querySelector('.modal-body')
        const myModal = new Modal(msgModal)
        const modalFooter = msgModal.querySelector('.modal-footer')
        modalBody.innerText = 'Account cannot be recovered. Do you wish to proceed?'
        modalFooter.innerHTML = ''
        modalFooter.innerHTML = `
        <button  class="close-delete button button-border button-light button-rounded">Close</button>
        <button  class="confirm-delete del-acct-btn button button-border button-light button-rounded">Delete</button>
        `

        modalFooter.querySelector('.close-delete').onclick = () => {
            myModal.hide()
        }

        modalFooter.querySelector('.confirm-delete').onclick = () => {
            fetch('/deleteAccount', {
                method: "POST",
            })
                .then(res => res.json())
                .then(json => {
                    if (json.deleted) {
                        logout()
                        localStorage.clear()
                    }
                })
        }

        myModal.show()
    }

    function logout() {
        fetch("/logout", { method: "POST" })
            .then(res => res.json())
            .then(json => {
                if (json.loggedOut) {
                    setLoggedIn({})
                    window.location.href = "/"
                }
                else {
                    alert('log out failed!')
                }
            })
    }



    return (
        <>
            {loggedIn.email ?
                <section id="myaccount">
                    <div className="content-wrap">

                        <div className="row justify-content-center">
                            <div className="col col-md-8">
                                <ul class="list-group p-2">
                                    <h1 id="myaccount-page-title" class="display-4">Account</h1>
                                    <br />
                                </ul>
                            </div>
                        </div>




                        <div className="row justify-content-center mt-5 p-4">
                            <div className="ps-4 col col-md-8">
                                <div class="row mb-3">
                                    <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                                    <div class="col-sm-10">
                                        <input id="email-input" type="email" placeholder={loggedIn.email}
                                            onChange={event => setEmail(event.target.value)}
                                            class="form-control"
                                            autoComplete="new-password" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label for="inputPassword3" class="col-sm-2 col-form-label">Password</label>
                                    <div class="col-sm-10">
                                        <input type="password"
                                            id="password-input"
                                            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$"
                                            placeholder="********"
                                            onChange={event => setPassword(event.target.value)}
                                            class="form-control required password"
                                            autoComplete="new-password" />
                                    </div>
                                </div>

                                <div className="">
                                    <button onClick={update} type="submit" class="button button-border button-light button-rounded">Update</button>
                                    <button onClick={deleteAccount} id="bookmark-trash-btn" type="submit" class="bookmark-trash-btn button button-border button-light button-rounded">Delete</button>
                                </div>

                            </div>
                        </div>

                        <div className="row justify-content-center mt-5 p-4">
                            <div className="ps-4 col col-md-8">
                                {loggedIn.admin ? <Admin loggedIn={loggedIn} />
                                    : <div className="container">
                                        <section id="content">
                                            <div className="content-wrap">
                                                <div className="container clearfix py-5 my-5">

                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>

                </section>
                : <div className="py-5 my-5">
                    <div className="container py-5 my-5">
                        <section id="content">
                            <div className="content-wrap">
                                <div className="container clearfix py-5 my-5">
                                    <div class="jumbotron">
                                        <h1 className="center display-5 fw-bold"> Please login to access this page.</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            }
        </>
    )
}