import { useRef, useState } from "react"
import { useStoryState } from "../../hooks/storyState-hook";


export default function NoteModal() {
    const { createNote } = useStoryState();
    const [noteText, setNoteText] = useState("")
    const noteModalRef = useRef()
  
    return (
        <div id='note-modal' ref={noteModalRef} class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-body">
                        <textarea id="text-area" class="form-control" value={noteText} onChange={e => setNoteText(e.target.value)} rows="3"></textarea>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" data-bs-dismiss="modal" onClick={()=>{createNote(noteText,  JSON.parse(noteModalRef.current.dataset.popoverRef)); setNoteText('')}} id="save-btn" class="btn">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}