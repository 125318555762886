import React, { useEffect } from "react";
import MusicPagePlayer from "../audio/MusicPagePlayer"
import Album from "../audio/Album"
import AlbumData from "../../config/albumData.json"



export default function Music() {


  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
  }, [])

  return (
    <section id="content">
      <div className="content-wrap">
        <div className="container clearfix">

          {/* <!-- Albums */}
          {/* ============================================= --> */}
          <div class="row row-cols-1 row-cols-lg-3 g-2">

            {/* POPULATE ALBUMS */}
            {AlbumData.albums.map((album, i) => (
              <Album key={album.albumId}
                albumNum={i + 1}
                albumData={album}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Interface for interacting with currently playing track */}
      <MusicPagePlayer />
    </section>

  );
};


