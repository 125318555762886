import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// hooks
import AudioManagerProvider from "./hooks/audioManager-hook";
import StoryStateProvider from "./hooks/storyState-hook";
import LoggedInProvider from "./hooks/loggedin-hook";

// css
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.css';
import "./css/index.css";

import App from "./App";
import MusicAudioManagerProvider from "./hooks/musicAudioManager";


const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <StoryStateProvider>
      <LoggedInProvider>
        <AudioManagerProvider>
          <MusicAudioManagerProvider>
            <App />
          </MusicAudioManagerProvider>
        </AudioManagerProvider>
      </LoggedInProvider>
    </StoryStateProvider>
  </BrowserRouter>
);
