import { useNavigate, Link } from "react-router-dom";
import { useLoggedIn } from "../../hooks/loggedin-hook"

export default function Logout() {
    const navigate = useNavigate();
    const { setLoggedIn } = useLoggedIn()

    function logout(evt) {
        evt.preventDefault()
        fetch("/logout", { method: "POST" })
            .then(res => res.json())
            .then(json => {
                if (json.loggedOut) {
                    setLoggedIn({})
                    localStorage.removeItem('user')
                    navigate('/')
                    navigate(0)
                }
                else {
                    alert('log out failed!')
                }
            })
    }

    return (
        <div onClick={(evt) => logout(evt)} className=" button button-border button-light button-rounded">Logout</div>
    )

}