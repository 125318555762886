import { useEffect, useState } from "react";
import { useMusicAudioManager } from "../../hooks/musicAudioManager";
import ProgressBar from "./ProgressBar";
import VolumeControls from "./VolumeControls";
import "./storyPlayer.css";

export default function MusicPagePlayer() {
    const [isPlaying, setIsPlaying] = useState(null)
    const { toggledTrack, muted,
        setMuted, setGain,
        gain, elapsed,
        duration, getSeekTime, progressDisabled } = useMusicAudioManager()


    useEffect(() => {
        if (toggledTrack?.isPlaying) {
            fadeInInterface()
        }
        else if (toggledTrack === null) {
            hide();
        }
    }, [toggledTrack?.isPlaying]);

    useEffect(() => {
        if (isPlaying != null && toggledTrack) {
            const selector = `[data-path="${toggledTrack?.path}"][data-album="${toggledTrack?.albumName}"]`
            const button = document.querySelector(selector)
            button.querySelector('i').click()
        }
    }, [isPlaying])

    function hide() {
        const iface = document.querySelector(".audio-interface-light");
        iface.classList.remove("interface-fadein");
        iface.classList.remove("interface-fadeout");
        iface.style = "pointerEvents:none";
    }

    function fadeInInterface() {
        const iface = document.querySelector(".audio-interface-light");
        iface.classList.add("interface-fadein");
        iface.classList.remove("interface-fadeout");
        iface.style = "pointerEvents:auto";
    }

    return (
        <div className="row audio-interface-light shadow-lg p-2 align-items-center justify-content-evenly" style={{ pointerEvents: "none" }}>

            <div className="col-2">
                <i onClick={() => setIsPlaying(!isPlaying)} className={"audioPlay-Btn master-play " + (toggledTrack?.isPlaying
                    ? "bi bi-pause-circle-fill"
                    : "bi bi-play-circle-fill")
                }>
                </i>
            </div>

            <div className="text-center col-6 pb-3">
                <div className="track-title ">{toggledTrack?.trackName}</div>
                <ProgressBar elapsed={elapsed} duration={duration} seekCallback={getSeekTime} disabled={progressDisabled} />
            </div>
            <div className="col-2" id="volume-block-dark">
                <div className="d-flex flex-row-reverse d-none d-sm-block">
                    <VolumeControls setMuted={setMuted} muted={muted} setVolume={setGain} volume={gain} theme={'light'} />
                </div>
            </div>
        </div>
    );
};
