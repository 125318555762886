import { Offcanvas } from "bootstrap"
import Menu from "./Menu";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import "./header.css"


export default function Header() {
  const loc = useLocation();
  let color = "";
  // eslint-disable-next-line default-case
  switch (loc.pathname) {
    case "/":
    case"/credits":
      color = "transparent-header dark";
      break;
    case "/music":
      color = "light";
      break;
    default:
      color = "dark";
  }

  // Style header based on Y location. Adds classes if not at top of page
  const headerElement = useRef();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        headerElement.current.classList.add("sticky-header");
        if (loc.pathname == "/") {
          headerElement.current.classList.add("semi-transparent");
        }
      } else {
        headerElement.current.classList.remove("sticky-header");
        if (loc.pathname == "/") {
          headerElement.current.classList.remove("semi-transparent");
        }
      }
    });
  }, []);


  function toggleSidepanel() {
    const oc = document.querySelector('.offcanvas')
    const myOffcanvas = new Offcanvas(oc)
    myOffcanvas.toggle()
  }

  return (
    <header id="header" ref={headerElement} className={color} data-logo-height="70" data-sticky-class="semi-transparent">
      <div id="header-wrap">
        <div className="container-fluid">

          <nav class="navbar navbar-expand-lg">

            <div class="container-fluid">

              <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>

              {/* hide smaller than lg */}

              <a class="btn navbar-brand d-lg-none ms-auto" type="button" onClick={toggleSidepanel} aria-controls="offcanvasRight">
                <i className="icon-plus"></i>
              </a>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <Menu />
                </ul>

                <a class="btn navbar-brand d-none d-lg-block" type="button" onClick={toggleSidepanel} aria-controls="offcanvasRight">
                  <i className="icon-plus"></i>
                </a>
              </div>
            </div>
          </nav>

        </div>
      </div>
      {loc.pathname === '/' ?
        <div className="header-wrap-clone"></div>
        : null}
    </header>
  );
};

