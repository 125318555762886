import { useEffect, useState } from "react"
import { useStoryState } from "../../hooks/storyState-hook"
import "./admin.css"

export default function Admin({ loggedIn }) {
    const [users, setUsers] = useState(null)
    const { fetchStoryState } = useStoryState()

    function getUsers() {
        fetch('/getUsers', { method: 'POST' })
            .then(res => res.json())
            .then((data) => {

                setUsers(data.sort((a, b) => {
                    const nameA = a.email.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.email.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    // names must be equal
                    return 0;
                }))

            })
    }


    useEffect(() => {
        getUsers()
    }, [])

    function toggleAdmin(email) {
        if (email) {
            fetch('/toggleAdmin', {
                method: 'POST',
                body: JSON.stringify({ email }),
                headers: { "Content-Type": "application/json" },

            }).then(getUsers)
        }
    }

    function adminUnlock(userEmail) {
        fetch('/adminUnlock', {
            method: 'POST',
            body: JSON.stringify({ userEmail }),
            headers: { "Content-Type": "application/json" },
        }).then(getUsers)
            .then(fetchStoryState)
    }

    const renderedUsers = users?.map((user) =>
        <tr>
            <td>{user.activation_token ? <i class="bi bi-circle-fill red"></i> : <i class="bi bi-circle-fill green"></i>}</td>
            <td>
                <span class="form-check form-switch">
                    <input class="form-check-input" onChange={() => {
                        toggleAdmin(user.email)


                    }} type="checkbox" checked={user.admin} disabled={user.email === loggedIn.email} />
                </span></td>
            <td>{user.email}</td>
            <td>{user.paypal_transaction?.custom ? 'True' : 'False'}</td>

            <td>
                <span class="form-check form-switch">
                    <input class="form-check-input" onChange={() => {
                        adminUnlock(user.email)
                    }} type="checkbox" checked={user.paypal_transaction?.custom} disabled={user.paypal_transaction?.custom} />
                </span>
            </td>
            <td>
                <button onClick={() => alert('coming soon')} id="bookmark-trash-btn" className="btn">
                    <i class="bi bi-trash3"></i>
                </button>
            </td>
        </tr>
    )

    return (
        <>
            <div className="table-responsive user-table">
                <table class="table table-striped table-hover align-middle">
                    <thead>
                        <tr>
                            <th scope="col">Active</th>
                            <th scope="col">Admin</th>
                            <th scope="col">Email</th>
                            <th scope="col">Paid</th>
                            <th scope="col">Unlock</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        {renderedUsers}
                    </tbody>
                </table>
            </div>


        </>
    )


}






