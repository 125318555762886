import { useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import { useLoggedIn } from "../../hooks/loggedin-hook"
import { Modal } from "bootstrap";

import Login from "../auth/LoginForm"
import BackgroundPlayer from "../audio/BackgroundPlayer";
import Logout from "../auth/LogoutButton";
import Map from "./Map";

import "./sidePanel.css"
import fire from "../../icons/fire.svg"



export default function SidePanel() {
  const { setLoggedIn, loggedIn } = useLoggedIn()
  const loc = useLocation()
  const closeButtonRef = useRef(null)
  const mapModal = useRef(null)

  let theme = ""
  // eslint-disable-next-line default-case
  switch (loc.pathname) {
    case '/music':
      theme = "light"
      break
    default:
      theme = "dark"
      break
  }




  function handleMap() {
    const map = document.getElementById('map')
    if (map.classList.contains('show')) {
      mapModal.current.hide()
    }
  }


  useEffect(() => {
    const map = document.getElementById('map')
    mapModal.current = new Modal(map)
    document.addEventListener('click', handleMap)
    return () => document.removeEventListener('click', handleMap)
  }, [])


  function closePanel() {
    closeButtonRef.current.click()
  }

  return (

    <div class={`offcanvas offcanvas-end ${theme}`} tabindex="-1" aria-labelledby="offcanvasRight">

      <i id="close-sidepanel-button" ref={closeButtonRef} data-bs-dismiss="offcanvas" class="bi bi-x-square-fill"></i>

      <div class="offcanvas-body d-flex flex-column align-items-center">


        {!loggedIn?.email ?
          <div className="p-2">
            <Login /> </div>
          :
          <>

            <Link onClick={closePanel} to='/unlock' class="ps-4 sidepanel-nav-bar  d-flex align-items-center">
              {loggedIn.paypal_transaction.txn_id ?
                <div id="donation-icon">
                  <img src={fire} alt="already donated logo"></img>
                </div>
                : <i class="bi-lock-fill sidepanel-nav-icon"></i>
              }
              <span className="px-4">Unlock Music</span>
            </Link>

            <Link onClick={closePanel} to='/bookmarks' class="ps-4  sidepanel-nav-bar  d-flex align-items-center">
              <i class="bi-bookmark-fill sidepanel-nav-icon"></i>
              <span className="px-4">Bookmarks</span>
            </Link>

            <Link onClick={closePanel} to='/notes' class="ps-4 sidepanel-nav-bar  d-flex align-items-center">
              <i class="bi bi-sticky-fill sidepanel-nav-icon"></i>
              <span className="px-4">Notes</span>
            </Link>

            <div onClick={() => mapModal.current.show()} class="ps-4 sidepanel-nav-bar  d-flex align-items-center" >
              <i class="bi-map-fill sidepanel-nav-icon"></i>
              <span className="px-4">Map</span>
            </div>

            <Link onClick={closePanel} to='/myaccount' class="ps-4 sidepanel-nav-bar  d-flex align-items-center">
              <i class="bi bi-person-square sidepanel-nav-icon"></i>
              <span className="px-4">Account</span>
            </Link>


            <div class="pt-3 my-auto">
              <BackgroundPlayer />

              <div class="text-center mt-4" id="logout">
                <Logout setLoggedIn={setLoggedIn} />
              </div>

            </div>
          </>
        }
      </div>

      <Map />
    </div>
  );
}










