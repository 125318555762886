import { useNavigate, useParams } from "react-router-dom";
import { useStoryState } from "../../hooks/storyState-hook";
import { useLoggedIn } from "../../hooks/loggedin-hook"
import { useEffect } from "react";
import hint from "../../icons/bookmarks_and_notes_v.png"

import "./highlight.css"
import Pagination from "../common/Pagination";


export default function Bookmark() {
    const { storyState, deleteHighlight } = useStoryState();
    const { loggedIn } = useLoggedIn()
    const { index } = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        const [...titles] = document.querySelectorAll('.bookmark-title')
        if (titles.length > 0) {
            const result = titles.find(elem => elem.textContent === index)
            if (result) {
                result.scrollIntoView({ block: "center", behavior: "instant" });
            }
            else {
                window.scrollTo(0, 0)
            }
        }
    }, [storyState?.bookmarks])



    function trash() {
        const [...checkboxes] = document.querySelectorAll('.form-check-input')
        const checked = checkboxes.filter(checkbox => checkbox.checked === true)
        const ids = checked.map(checked => checked.id)
        deleteHighlight(ids)
        checkboxes.forEach(check => check.checked = false)
    }


    function formatDate(datetime) {
        const options = {
            year: 'numeric',
            month: 'short', day: 'numeric'
        };

        const date = new Date(datetime);
        return date.toLocaleDateString("en-US", options)
    }

    const renderedBookmarks = storyState?.bookmarks?.toReversed().map(bookmark => (
        <div class="list-group-item d-flex align-items-center justify-content-between">

            <div className="d-flex">
                <div class="bookmark-form-check form-check">
                    <input class="form-check-input" type="checkbox" value="" id={bookmark.id} />
                </div>

                <div >{formatDate(bookmark.datetime)} - </div>
                &nbsp;
                <div className="bookmark-title pb-2">{bookmark.act}.{bookmark.scene}.{bookmark.section}</div>
            </div>

            <div class="button button-border button-light button-rounded"
                onClick={() => navigate(`/story/${bookmark.act}/${bookmark.scene}/${bookmark.section}`)}>
                View
            </div>


        </div>
    ))


    return (
        <>
            {loggedIn?.email ?
                <section id="bookmarks">
                    <div className="content-wrap">

                        <div className="row justify-content-center">
                            <div className="col col-md-8">
                                <ul class="list-group p-2">
                                    <h1 id="bookmarks-page-title" class="display-4">Bookmarks</h1>
                                    <div className="col col-md-3">
                                        <img src={hint} alt="Bookmarks and Notes" />
                                    </div>
                                    <br />
                                    <p>Click on a character name anywhere in the story (as shown above) to add Bookmarks, which you can revisit from this page.</p>
                                </ul>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="ps-4 col col-md-8">
                                {storyState?.bookmarks.length > 0
                                    &&
                                    <button id="bookmark-trash-btn" onClick={trash} className="btn">
                                        <i class="bi bi-trash3"></i>
                                    </button>
                                }
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col col-md-8">
                                <ul class="list-group p-2">
                                    <Pagination numResults={storyState?.bookmarks?.length} resultsPerPage={10}
                                        children={renderedBookmarks} />
                                </ul>
                            </div>
                        </div>


                    </div>
                </section>
                :
                <div className="py-5 my-5">
                    <div className="container py-5 my-5">
                        <section id="content">
                            <div className="content-wrap">
                                <div className="container clearfix py-5 my-5">
                                    <div class="jumbotron">
                                        <h1 className="center display-5 fw-bold"> Please login to access this page.</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>}

        </>


    )
}