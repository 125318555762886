import React, { createContext, useContext, useEffect, useState } from "react";

const LoggedInContext = createContext();
export const useLoggedIn = () => useContext(LoggedInContext);

export default function LoggedInProvider({ children }) {
    const [loggedIn, setLoggedIn] = useState(JSON.parse(localStorage.getItem('user')) || {})


    useEffect(()=>{
        if(loggedIn?.email){
            localStorage.setItem('user', JSON.stringify(loggedIn))
        }
    },[loggedIn])

    return (
        <LoggedInContext.Provider value={{ setLoggedIn, loggedIn }}>
            {children}
        </LoggedInContext.Provider>
    );
}
