import { useEffect} from "react";
import { useLoggedIn } from "../../hooks/loggedin-hook"
import { Carousel} from "bootstrap"


import "./unlock.css"

const host = 'fatalfire.com'

export default function Unlock() {
	const { loggedIn } = useLoggedIn()


	useEffect(() => {
		const carouselDiv = document.querySelector('.carousel')
		if (carouselDiv) {
			new Carousel(carouselDiv, {
				interval: 3000,
				wrap: true,
				ride: "carousel"
			})
		}
	}, [])
	
	
	return (
		<section id="content">
			<div class="content-wrap py-5 my-5">
				<div class="container clearfix py-5">
					<div class="fancy-title title-border-color"><h1>Unlock story content and help a charity</h1></div>
					You don’t have to pay anything to experience any part of Fatal Fire—everything is unlocked as you progress through the story. However, if you’d like to listen to the three <a href="music">main story albums</a> right now without having to unlock anything, you can pay any amount to unlock those by clicking this button...
					<form name="_xclick" action="https://www.paypal.com/us/cgi-bin/webscr" method="post">
						<input type="hidden" name="cmd" value="_xclick" />
						<input type="hidden" name="business" value="fatalfire@fatalfire.com" />
						<input type="hidden" name="currency_code" value="USD" />
						<input type="hidden" name="item_name" value="Pay What You Want" />
						<input type="hidden" name="custom" value={loggedIn?.email}  ></input>
						<input type="hidden" name="return" value={`https://${host}/unlocking-music`}/>
						<input type="hidden" name="cancel_return" value={`https://${host}/unlock`}/>
						<input type="hidden" name="rm" value="1"/>
						<input className="mt-1" type="image" src={process.env.PUBLIC_URL + "/images/whatever.gif"} border="0" name="submit" alt="Make payments with PayPal - it's fast, free and secure!" />
					</form>
			

					<span className="mb-2" >...and half of whatever you give, minus the fees we receive from PayPal, will go to a great charity like the ones shown below—and you can revisit this page any time, and give more than once if you'd like. </span>

					<div id="carouselCharity" class="carousel slide mt-3">
						<div class="carousel-inner">
							<div class="carousel-item">
								<img src={process.env.PUBLIC_URL + "/images/charities/sjch.jpg"} class="d-block w-50" alt="St. Jude's Children's Hospital" />
							</div>
							<div class="carousel-item active">
								<img src={process.env.PUBLIC_URL + "/images/charities/aspca.jpg"} class="d-block w-50" alt="American Society for the Prevention of Cruelty to Animals" />
							</div>
							<div class="carousel-item">
								<img src={process.env.PUBLIC_URL + "/images/charities/mpt.jpg"} class="d-block w-50" alt="Mercury Phoenix Trust" />
							</div>
							<div class="carousel-item">
								<img src={process.env.PUBLIC_URL + "/images/charities/aa.jpg"} class="d-block w-50" alt="Alzheimer's Association" />
							</div>
							<div class="carousel-item">
								<img src={process.env.PUBLIC_URL + "/images/charities/maw.jpg"} class="d-block w-50" alt="Make-A-Wish" />
							</div>
						</div>
						<button class="carousel-control-prev" type="button" data-bs-target="#carouselCharity" data-bs-slide="prev">
							<span class="carousel-control-prev-icon" aria-hidden="true"></span>
							<span class="visually-hidden">Previous</span>
						</button>
						<button class="carousel-control-next" type="button" data-bs-target="#carouselCharity" data-bs-slide="next">
							<span class="carousel-control-next-icon" aria-hidden="true"></span>
							<span class="visually-hidden">Next</span>
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};
