import "./pagination.css"
import { useEffect, useState } from "react"

export default function Pagination({ numResults, resultsPerPage, children }) {
    const [currentPage, setCurrentPage] = useState(null)
    const pages = Math.ceil(numResults / resultsPerPage)

    useEffect(() => {
        if (currentPage != null) {
            const activeBtn = document.getElementById(`pagination-btn-${currentPage}`)
        }
    }, [currentPage])



    function nextPage() {
        if (currentPage < pages) {
            setCurrentPage(currentPage + 1)
        }
    }

    function prevPage() {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1)
        }
    }



    return (
        <>
            {children}
            <div class="d-flex justify-content-center text-center align-items-center mt-4">
                {numResults > 10 ?
                    <><button onClick={prevPage} className="button button-border button-light button-rounded side-nav booknote-btn"><i class="bi bi-caret-left-fill"></i></button>
                        <span className=""> 1 OF {Math.ceil(numResults / resultsPerPage)}</span>
                        <button onClick={nextPage} className="button button-border button-light button-rounded side-nav booknote-btn"><i class="bi bi-caret-right-fill"></i></button>
                    </>
                    : null}
            </div>
        </>
    )
}