import { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useLoggedIn } from "./hooks/loggedin-hook"


// components
import Layout from "./components/common/Layout";
import StoryPage from "./components/content/StoryPage";
import Home from "./components/content/Home";
import Music from "./components/content/Music";
import Privacy from "./components/content/Privacy";
import Credits from "./components/content/Credits";
import NoMatch from "./components/content/NoMatch";
import UpdateEmail from "./components/auth/UpdateEmail";
import Unlock from "./components/content/Unlock";
import ActivateAccount from "./components/auth/ActivateAccount";
import ChangePasswordPage from "./components/auth/ChangePasswordPage";
import SendPwdResetPage from "./components/auth/SendPwdResetPage";
import Bookmark from "./components/content/Bookmark";
import Notes from "./components/content/Notes";
import MyAccount from "./components/content/MyAccount";
import UnlockingMusic from "./components/content/UnlockingMusic";
import ComingSoon from "./components/content/ComingSoon";


export default function App() {
  const { setLoggedIn, loggedIn } = useLoggedIn()
  const location = useLocation();
  const navigate = useNavigate();


  // refresh the page, reload scripts
  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      console.log(location)
      if (location.pathname === '/') {
        navigate(0)
      }
    })
  }, [])


  // show grepcatcha badge only on home
  useEffect(() => {
    const elem = document.querySelector('.grecaptcha-badge')
    if (elem) {
      if (location.pathname === '/') {
        elem.style.visibility = 'visible'
        return
      }
      elem.style.visibility = 'hidden'
    }
  })

  // does user have an active session? 
  useEffect(() => {
    fetch('/checkLogin', { method: "POST" })
      .then(res => res.json())
      .then(user => {
        if (user.email) {
          setLoggedIn(user)
        }
      })
  }, [])



  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          {/* protected routes */}
          {/* https://github.com/remix-run/react-router/issues/8554 */}
          {loggedIn.email &&
            <Route path="/story/:actParam/:sceneParam" element={<StoryPage />} />}
          {loggedIn.email &&
            <Route path="/story/:actParam/:sceneParam/:sectionParam" element={<StoryPage />} />}
          {loggedIn.email &&
            <Route path="/myaccount" element={<MyAccount />} />}
          {loggedIn.email &&
            <Route path="/comingsoon" element={<ComingSoon />} />}
          {loggedIn.email &&
            <Route path="/bookmarks" element={<Bookmark />} />}
          {loggedIn.email &&
            <Route path="/bookmarks/:index" element={<Bookmark />} />}
          {loggedIn.email &&
            <Route path="/notes" element={<Notes />} />}
          {loggedIn.email &&
            <Route path="/notes/:index" element={<Notes />} />}
          {loggedIn.email &&
            <Route path="/unlock" element={<Unlock />} />}
          {loggedIn.email &&
            <Route path="/unlocking-music" element={<UnlockingMusic />} />}


          <Route path="/music" element={<Music />} />

          {/* public routes */}
          <Route path="/activation/:token" element={<ActivateAccount />} />
          <Route path="/reset-password" element={<SendPwdResetPage />} />
          <Route path="/change-password/:token" element={<ChangePasswordPage />} />
          <Route path="/updateEmail/:token" element={<UpdateEmail />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/credits" element={<Credits />} />
          <Route path="*" element={<NoMatch />} />

        </Route>
      </Routes>
    </div>
  );
}

