import { useState } from "react"
import { Modal } from "bootstrap"

export default function Registration({ setRegistered }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    function handleSubmit(e) {

        e.preventDefault()

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6Lc1dWQqAAAAABViNnaOLBG5BJRAP89a7r00ZYoE',
                { action: 'submit' }).then(function (token) {

                    fetch('/register',
                        {
                            method: 'POST',
                            body: JSON.stringify({ email, password, token }),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    )
                        .then(res => res.json())
                        .then(msg => {
                            const msgModal = document.getElementById('msg-modal')
                            const modalBody = msgModal.querySelector('.modal-body')
                            const myModal = new Modal(msgModal)
                            modalBody.innerText = msg.status
                            myModal.show()

                            if (msg.registered) {
                                setRegistered(true)
                            }
                        })
                })
        })
    }


    return (
        <form className='form-auth' onSubmit={handleSubmit}>
            <div className='input-group mb-1'>
                <input
                    autoComplete='off'
                    type='email'
                    title='Email is invalid or already in use.'
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    name='email'
                    className='form-control form-control-sm not-dark required email'
                    placeholder='Email Address'
                    required
                />
            </div>

            <input
                autoComplete='off'
                type='password'
                // https://owasp.org/www-community/OWASP_Validation_Regex_Repository
                // 8 to 64 character password requiring numbers and both lowercase and uppercase letters
                pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$'
                title='8 to 64 character password requiring numbers and both lowercase and uppercase letters.'
                id='widget-register-form-password'
                name='password'
                value={password}
                onChange={event => setPassword(event.target.value)}
                className='form-control form-control-sm not-dark required email'
                placeholder='Password'
                required />

            {/* failed registrarion message */}
            <button type='submit' className='button button-border button-light button-rounded'>Register</button>
        </form>

    )
}