export default function MessageModal() {

    return (
        <div class="modal fade" id="msg-modal" data-bs-backdrop="false"
            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                    </div>
                    <div class="modal-body">

                    </div>
                    <div class="modal-footer">
                        <button id="dismiss-btn" type="button" class="button button-border button-light button-rounded" 
                        data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>)
}