import { Modal } from "bootstrap";
import { useEffect, useRef } from "react";
export default function Lightbox({ domContainer }) {
    const modal = useRef(null)


    useEffect(() => {
        const myModal = new Modal(modal.current)
        domContainer.addEventListener('click', (e) => {
            e.preventDefault()
            myModal.toggle()
        })
    }, [])


    return (
        <>
            <span dangerouslySetInnerHTML={{ __html: domContainer.outerHTML }} />

            {/* lightbox */}
            <div class="modal fade" data-bs-backdrop="false" ref={modal} aria-hidden="true"  tabindex="-1">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <img style={{"minWidth":"0px"}} src={domContainer.href} ></img>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
