import React, { useEffect, useRef, useState } from "react";
import { createPortal } from 'react-dom';
import * as ReactDOM from "react-dom/client";
import { useParams, useNavigate } from 'react-router-dom';
import { Popover } from "bootstrap"
import { useStoryState } from "../../hooks/storyState-hook";
import { useAudioManager } from "../../hooks/audioManager-hook";
import AudioButton from "../audio/AudioButton.jsx";
import SoundButton from "../audio/SoundButton";
import StoryPlayer from "../audio/StoryPlayer";
import TextDropdown from "../common/TextDropdown";
import Lightbox from "../common/Lightbox"

import StoryConfig from "../../config/storyConfig.json";

import "../../components/audio/audioButton.css";
import "./storyPage.css"


const scenesPerAct = StoryConfig.info.acts;
const actsLength = StoryConfig.info.acts.length;


export default function StoryPage() {
  const navigate = useNavigate();
  const { unlockedTracks, storyState, createBookmark,
    showNoteModal, html, getHTML } = useStoryState();

  // navigation
  const { actParam, sceneParam, sectionParam } = useParams();

  // audio
  const { setSceneTracks } = useAudioManager()
  const [audioButtons, setAudioButtons] = useState(null);

  // set current scene from the menu
  const prevButton = useRef(null);
  const nextButton = useRef(null);


  // set from url param
  useEffect(() => {

    if (actParam != null
      && sceneParam != null
      && StoryConfig.actsReady.includes(parseInt(actParam))
    ) {

      // fetch the new scene html
      getHTML(actParam, sceneParam)

      // blur nav buttons so user can't tab through pages
      prevButton?.current?.blur();
      nextButton?.current?.blur();

      // set reference for book/note popover
      popoverRef.current.act = actParam
      popoverRef.current.scene = sceneParam
    }
    else {
      navigate('/comingsoon')
    }

  }, [actParam, sceneParam]);


  //  nav to next scene
  function nextScene() {
    const sceneParamInt = parseInt(sceneParam)
    const actParamInt = parseInt(actParam)
    const nextActReady = StoryConfig.actsReady.includes(actParamInt + 1)

    // does act n have a next scene
    if (sceneParamInt < scenesPerAct[actParamInt - 1]) {
      navigate(`/story/${actParamInt}/${sceneParamInt + 1}`)
    }
    // if next act is ready, go to next act
    else if (sceneParamInt === scenesPerAct[actParamInt - 1]
      && (actParamInt + 1 <= scenesPerAct.length && nextActReady)) {
      navigate(`/story/${actParamInt + 1}/${0}`)
    }
    //if next act does not exist, go to credits
    else if (actParamInt + 1 > actsLength) {
      navigate('/credits')
    }
    // if next act is not ready, go to coming soon
    else {
      navigate('/comingsoon')
    }
  }

  // nav to prev scene
  function prevScene() {
    const sceneParamInt = parseInt(sceneParam)
    const actParamInt = parseInt(actParam)

    if (sceneParamInt === 0 && actParamInt > 1) {
      navigate(`/story/${actParamInt - 1}/${scenesPerAct[actParamInt - 2]}`)
    }
    else if (sceneParamInt > 0) {
      navigate(`/story/${actParamInt}/${sceneParamInt - 1}`)
    }
    else {
      navigate(`/credits`)
    }
  }


  // html is received from server
  useEffect(() => {

    if (html) {

      // scroll to top
      window.scroll({ top: 0, left: 0, behavior: 'instant' });


      // parse and render lightboxes
      document.querySelectorAll('[data-lightbox="image"]').forEach((domContainer) => {
        const root = ReactDOM.createRoot(domContainer);
        root.render(<Lightbox domContainer={domContainer} />);
      });

      // if section is given, scroll to it
      try {
        const sectionElem = document.querySelector(`[data-section="${sectionParam}"`)
        sectionElem.scrollIntoView({ block: "center", behavior: "instant" });
      } catch (e) {
      }
    }

  }, [html]);

  // get references to audio buttons
  useEffect(() => {
    if (html) {

      // get references for the audio buttons
      setAudioButtons([])
      let abs = []
      document.querySelectorAll(".audioButton").forEach(async (domContainer) => {
        const { path, type } = domContainer.dataset;
        const trackName = path.split(/.m4a|\//)[3];
        abs.push({ domContainer, path, type, trackName })
      })
      setAudioButtons(abs)
      setSceneTracks(abs)
    }

  }, [html])


  // populate note and bookmarks
  useEffect(() => {

    populateNotes()
    populateBookmarks()

    // parse and render text dropdowns
    document.querySelectorAll(".textDropdown").forEach((domContainer) => {
      const root = ReactDOM.createRoot(domContainer);
      root.render(<TextDropdown markup={domContainer.innerHTML} />);
    });

  }, [html])


  function createAudioPortals() {
    return audioButtons.map(({ domContainer, type, trackName, path }) => {
      if (type === 'sound') {
        return createPortal(<SoundButton key={trackName + "-" + type} trackName={trackName} type={type} path={path} />, domContainer)
      }
      return createPortal(<AudioButton key={trackName + "-" + type} trackName={trackName} type={type} path={path} />, domContainer)
    })
  }


  //////////////////////////////////////// Highlight Functions //////////////////////////////////////////
  let popoverRef = useRef({ ref: null, timeoutId: null, section: null, act: null, scene: null })


  useEffect(() => {
    document.addEventListener('mouseover', onMouseOver)
    document.addEventListener('touchmove', onMouseOver)


    window.onpopstate = () => {
    }
    return () => document.removeEventListener('mouseover', onMouseOver)
  }, [storyState?.notes])


  function populateBookmarks() {

    storyState?.bookmarks?.forEach(bookmark => {
      const { act: bookmarkAct, scene: bookmarkScene, section: bookmarkSection, id: bookmarkID } = bookmark
      if ('' + actParam === bookmarkAct
        && '' + sceneParam === bookmarkScene) {
        const sectionElem = document.querySelector(`[data-section="${bookmarkSection}"`)

        // if section doesn't have bookmark
        if (sectionElem && !sectionElem.querySelector('.bi-bookmark')) {
          sectionElem.dataset.bookmarkid = bookmarkID
        }
      }
    })
  }

  function populateNotes() {
    storyState?.notes?.forEach(note => {
      const { act: noteAct, scene: noteScene, section: noteSection, id: noteID } = note
      if ('' + actParam === noteAct
        && '' + sceneParam === noteScene) {
        const sectionElem = document.querySelector(`[data-section="${noteSection}"`)

        // if section doesn't have note
        if (sectionElem && !sectionElem.querySelector('.bi-sticky')) {
          sectionElem.dataset.noteid = noteID
        }
      }
    })
  }

  useEffect(() => {

    if (html) {
      if (storyState?.bookmarks?.length > 0) {
        populateBookmarks()
      }
      if (storyState?.notes?.length > 0) {
        populateNotes()
      }
    }

  }, [storyState?.bookmarks, storyState?.notes])


  function resetPopover() {

    // dispose of the popover
    popoverRef.current.ref.dispose()
    // null the popover reference
    popoverRef.current = { ...popoverRef.current, ref: null, timeoutId: null, section: null }
    // set eventlistener
    document.removeEventListener('click', resetOnClick)
  }


  function onMouseOver(e) {

    // elements to show popover on
    if (e.target.nodeName === "B" && e.target.dataset.section) {

      // get section
      const { section } = e.target.dataset

      // if section has a popover, do nothing
      if (popoverRef.current.section === section) {
        return
      }

      // if we hover over a new section
      if (popoverRef.current.section
        && popoverRef.current.section != section) {
        // clear the timeout
        clearTimeout(popoverRef.current.timeoutId)
        resetPopover()
      }

      // create a new popover
      popoverRef.current.ref = createPopover(e.target)
      popoverRef.current.ref.show()
      popoverRef.current.section = section

      // set eventlistener
      document.addEventListener('click', resetOnClick)
      document.addEventListener('click', resetOnClick)


    }
  }

  function resetOnClick(e) {
    if (e.target.parentElement.className !== 'btn-group') {
      resetPopover()
    }
  }


  function createPopover(elem) {

    // bookmark button
    const bookmarkBtn = document.createElement('button')
    bookmarkBtn.classList.add('btn', 'popover-card-btn')
    bookmarkBtn.innerHTML = '<i style="pointer-events:none;" class="bi bi-bookmark"></i>'
    if (elem.dataset.bookmarkid) {
      bookmarkBtn.classList.add('inverse')
    }
    bookmarkBtn.onclick = function (e) {
      e.preventDefault()
      if (elem.dataset.bookmarkid) {
        navigate(`/bookmarks/${actParam}.${sceneParam}.${elem.dataset.section}`)
      } else {
        createBookmark({ ...popoverRef.current })
      }
      resetPopover()
    }

    // note button  
    const noteBtn = document.createElement('button')
    noteBtn.classList.add('btn', 'popover-card-btn')
    if (elem.dataset.noteid) {
      noteBtn.classList.add('inverse')
    }
    noteBtn.innerHTML = '<i style="pointer-events:none;" class="bi bi-pencil-square"></i>'
    noteBtn.onclick = function (e) {
      e.preventDefault()
      if (elem.dataset.noteid) {
        navigate(`/notes/${actParam}.${sceneParam}.${elem.dataset.section}`)
      } else {
        showNoteModal(popoverRef.current)
      }
      resetPopover()
    }


    // button group
    const btnGroup = document.createElement('div')
    btnGroup.classList.add('btn-group', 'mb-2')

    let poverElem = null
    // if section has a note
    if (elem.dataset.noteid) {

      btnGroup.append(bookmarkBtn)
      btnGroup.append(noteBtn)
      // btnGroup.append(trashBtn)

      const card = document.createElement('div')
      card.className = "popover-card"

      const pHeader = document.createElement('div')
      pHeader.className = 'note-popover-header d-flex justify-content-between'

      const assSpan = document.createElement('span')
      assSpan.innerHTML = `${actParam}.${sceneParam}.${elem.dataset.section}`

      const pDiv = document.createElement('div')
      pDiv.className = 'popover-note'

      const para = document.createElement('p')
      para.innerText = storyState.notes.find(note => note.id === elem.dataset.noteid).noteText

      pDiv.appendChild(para)

      pHeader.appendChild(btnGroup)
      pHeader.appendChild(assSpan)
      card.appendChild(pHeader)
      card.appendChild(pDiv)
      poverElem = card
    }
    else {
      btnGroup.append(bookmarkBtn)
      btnGroup.append(noteBtn)
      btnGroup.classList.add('no-note-popover')
      poverElem = btnGroup
    }

    var popover = new Popover(elem, {
      container: 'body',
      content: poverElem,
      html: true,
      trigger: 'click',
      template: `"<div class=\"story-popover\" role=\"tooltip\"><div class=\"popover-arrow\"></div><h3 class=\"popover-header\"></h3><div class=\"popover-body\"></div></div>"`
    })
    return popover
  }

  function renderNextButton() {
    if (actParam === '1' && sceneParam === '0') {
      if (!unlockedTracks.includes("Main Theme")) {
        return "hideNext"
      }
      else if (unlockedTracks.includes("Main Theme")) {
        return 'fadeNext'
      }
    }
  }

  return (
    <div>
      <section
        id="content"
        style={{
          minHeight: "90vh",
        }}
      >
        <div className="content-wrap py-0">
          <div className="container clearfix">
            <div className="container mt-6">
              <h1 className="center mb-0">
                {html ?
                  <>
                    <span>.:</span>
                    <strong id="act-and-scene">{sceneParam === '0' ? `Act ${actParam}` : `Act ${actParam}, Scene ${sceneParam}`} </strong>
                    <span>:.</span>
                  </>
                  : null}
              </h1>
              <br />
            </div>

            {html &&
              <>
                {/* Display current scene */}
                <div dangerouslySetInnerHTML={{ __html: html }} />
                {/* render portals */}
                {audioButtons !== null && createAudioPortals()}
              </>
            }

          </div>
        </div>

        {html ?
          <div id="sceneNavigation" className="center bottommargin-sm">
            {actParam === '1' && sceneParam === '0' ? null : (
              <button
                className="button button-border button-light button-rounded"
                onClick={() => { prevScene(); }}
                ref={prevButton}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
              </button>
            )}


            {/* NEXT BUTTON */}
            <button
              id="next-button"
              className={renderNextButton() + ' button button-border button-light button-rounded'}
              onClick={() => { nextScene(); }}
              ref={nextButton}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
              </svg>
            </button>
          </div>
          : null}

      </section>

      {/* Players */}
      <StoryPlayer />

    </div>
  );
}
