import { useEffect, useRef } from "react";
import "./ProgressBar.css";


export default function ProgressBar({ duration, elapsed, seekCallback, disabled }) {
  const progressBar = useRef(null)
  const isSeekingRef = useRef(false)
  const seekTimeRef = useRef(null)

  useEffect(() => {
    
    if (progressBar.current) {
      progressBar.current.onchange = onStopSeeking
      progressBar.current.oninput = setIsSeeking
    }

    return () => {
      if (progressBar.current) {
        progressBar.current.onchange = null
        progressBar.current.oninput = null
      }
    }
  })

  function onStopSeeking() {
    seekCallback(seekTimeRef.current)
    isSeekingRef.current = false
  }

  function setIsSeeking() {
    isSeekingRef.current = true
  }

  useEffect(() => {
    if (isSeekingRef.current) {
      return
    }
    progressBar.current.value = elapsed
  }, [elapsed])


  function secondsToHMS(seconds) {
    const h = String(Math.floor(seconds / 3600)).padStart(1, "0");
    const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const s = String(Math.floor(seconds % 60)).padStart(2, "0");
    return h === "0" ? `${m}:${s}` : `${h}:${m}:${s}`;
  }

  return (
    <div className="d-flex" id="progressBar">
      <div id="elapsedTime" className="time d-none d-sm-block">
        {secondsToHMS(elapsed)}
      </div>
      <input ref={progressBar} id="pbar" onInput={(e) => seekTimeRef.current = e.target.value}
        className="progressBar form-range" type="range" step="0.000001" max={duration} disabled={disabled} />
      <div id="trackDuration" className="time d-none d-sm-block">
        {secondsToHMS(duration)}
      </div>
    </div>
  );
};
