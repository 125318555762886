import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';


const MusicAudioManagerContext = createContext();
export const useMusicAudioManager = () => useContext(MusicAudioManagerContext);

export default function MusicAudioManagerProvider({ children }) {
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(null)
  const [toggledTrack, setToggledTrack] = useState(null)
  const [tracksState, setTracksState] = useState(null)
  const [progressDisabled, setProgressDisabled] = useState(null)

  const [duration, setDuration] = useState(0);
  const [elapsed, setElapsed] = useState(0);
  const audioElementRef = useRef(null)

  // gain and mute
  const [muted, setMuted] = useState(null)
  const [gain, setGain] = useState(localStorage.getItem('musicPageGain') || 1)
  const prevGain = useRef(null)

  const updateDisplayAnimID = useRef(null)
  const previousMusicTrack = useRef(null)
  const pauseTimeRef = useRef(null)


  useEffect(() => {
    if (location.pathname !== "/music") {
      audioElementRef.current?.pause()
      audioElementRef.current = null
      if(toggledTrack){
        setTracksState([{ trackName: toggledTrack.trackName, isPlaying: false }])
      }
      setToggledTrack(null)
    }
  }, [location])


  function getTracksList(albumName) {
    const [...tracksList] = document.querySelectorAll(`[data-album="${albumName}"]`)
    return tracksList.map(track => ({
      trackName: track.dataset.trackname, path: track.dataset.path,
      albumName: track.dataset.album, unlocked: track.dataset.unlocked === "true" ? true : false
    }))
  }

  function getNextUnlockedTrack(trackName, tracksList) {
    let current = tracksList.findIndex(track => track.trackName === trackName)
    while (current < tracksList.length - 1) {
      current++
      const nextTrack = tracksList[current]
      if (nextTrack.unlocked) {
        return { ...nextTrack, isPlaying: true }
      }
    }
    return null
  }

  function isLastUnlockedTrack(trackName, albumName) {
    const tracksList = getTracksList(albumName).reverse()
    const lastUnlocked = tracksList.find(track => track.unlocked === true)
    return lastUnlocked.trackName === trackName
  }

  // animation
  useEffect(() => {
    if (toggledTrack?.isPlaying) {
      // cancel previous
      cancelAnimationFrame(updateDisplayAnimID.current)
      updateDisplayAnimID.current = requestAnimationFrame(updateDisplay);
    } else {
      cancelAnimationFrame(updateDisplayAnimID.current)
    }
  }, [toggledTrack]);


  function updateDisplay() {
    setElapsed(audioElementRef.current.currentTime);
    updateDisplayAnimID.current = requestAnimationFrame(updateDisplay);
  }

  function handleToggledTrack(track) {

    // if a track is playing and a new track is toggled
    // pause the previous track and set source to empty string
    if (previousMusicTrack.current
      && previousMusicTrack.current?.trackName
      !== track?.trackName) {
      previousMusicTrack.current.audio.pause()
      setTracksState([{ trackName: previousMusicTrack.current.trackName, isPlaying: false }, { trackName: track.trackName, isPlaying: true }])
      pauseTimeRef.current = null
    }

    setToggledTrack(track)

    if (track?.isPlaying === true) {

      // set media session metadata
      const imageURL = `https://fatalfire.com/images/albumImages/${track?.albumName}/`
      const sizes = [1024]
      const artwork = sizes.map(size => { return { src: imageURL + `${size}.png`, sizes: `${size}x${size}`, type: "image/png" } })
      navigator.mediaSession.metadata = new MediaMetadata({
        title: track.trackName,
        artist: "Fatal Fire",
        album: track.albumName,
        artwork: artwork
      });

    

      // create audio element
      setIsLoading({ trackName: track.trackName, isLoading: true })
      setProgressDisabled(true)

      audioElementRef.current = new Audio(track.path)

      if(pauseTimeRef.current){
        audioElementRef.current.currentTime = pauseTimeRef.current
      }
      else{
        audioElementRef.current.currentTime = 0
      }


      // set duration when metadata is loaded
      audioElementRef.current.onloadedmetadata = () => {
        setDuration(audioElementRef.current.duration)
        setIsLoading({ trackName: track.trackName, isLoading: false })
        setProgressDisabled(false)

        audioElementRef.current.play()

      }

      // on ended find and play next track
      audioElementRef.current.onended = () => {
        if (!isLastUnlockedTrack(track.trackName, track.albumName)) {
          const tracksList = getTracksList(track.albumName)
          const nextTrack = getNextUnlockedTrack(track.trackName, tracksList)
          setTracksState([{ trackName: track.trackName, isPlaying: false }, { trackName: nextTrack.trackName, isPlaying: true }])
          if (nextTrack) {
            handleToggledTrack(nextTrack)
          }
        }
        else {
          setTracksState([{ trackName: track.trackName, isPlaying: false }])
          setToggledTrack(null)
        }
      }
    }
    else if (track?.isPlaying === false) {
      pauseTimeRef.current = audioElementRef.current.currentTime
      audioElementRef.current.pause()
    }

    previousMusicTrack.current = { trackName: track.trackName, audio: audioElementRef.current }
  }




  useEffect(() => {

    if (muted && gain > 0) {
      prevGain.current = gain;
      setGain(0);
    }
    else if (!muted && parseFloat(prevGain.current) > 0) {
      setGain(parseFloat(prevGain.current))
    }
    else if (muted && gain <= 0) {
      prevGain.current = 0
      setMuted(false)
      setGain(1)
    }
  }, [muted])

  useEffect(() => {
    if (toggledTrack) {
      audioElementRef.current.volume = gain
    }
    localStorage.setItem('musicPageGain', gain)
  }, [gain])


  function getSeekTime(seekTime) {
    audioElementRef.current.currentTime = seekTime;
  }

  return (
    <MusicAudioManagerContext.Provider value={{
      isLoading,
      setToggledTrack,
      handleToggledTrack,
      toggledTrack,
      muted,
      setMuted,
      setGain,
      gain,
      elapsed,
      duration,
      getSeekTime,
      tracksState,
      progressDisabled
    }}>
      {children}
    </MusicAudioManagerContext.Provider>
  );
}


