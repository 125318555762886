import { useEffect } from 'react'
import { Modal } from 'bootstrap'
import { useParams } from 'react-router-dom'

export default function UpdateEmail() {
    const { token } = useParams()


    useEffect(() => {

        fetch('/confirmUpdatedEmail',
            {
                method: 'POST',
                body: JSON.stringify({ token }),
                headers: { 'Content-Type': 'application/json' }
            }
        )
            .then(res => res.json())
            .then(json => {
                if (json.updated) {
                    logout()
                }
            })
    }, [])



    function logout() {
        // logout
        fetch("/logout", { method: "POST" })
            .then(res => res.json())
            .then(json => {
                const msgModal = document.getElementById('msg-modal')
                const modalBody = msgModal.querySelector('.modal-body')
                const myModal = new Modal(msgModal)
                const modalFooter = msgModal.querySelector('.modal-footer')
                modalFooter.innerHTML = ''

                if (json.loggedOut) {
                    // show modal  
                    modalBody.innerText = "Email successfully updated."
                }
                else {
                    modalBody.innerText = "Email update failed!."
                   
                }
                modalFooter.innerHTML = `<a href="/" id="close-btn" type="button" class="button button-border button-light button-rounded" >Continue</a>`
                myModal.show()
            })
    }


    return (

        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix py-5 my-5">
                    <div class="jumbotron py-5 my-5">
                        <div className='py-5'><h1 class="display-4"></h1>
                            <p class="lead"></p>
                            <hr class="my-4" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}





