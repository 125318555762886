import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLoggedIn } from "../../hooks/loggedin-hook"
import {useStoryState} from "../../hooks/storyState-hook"

export default function UnlockingMusic() {

    const navigate = useNavigate();
    const { setLoggedIn } = useLoggedIn()
    const {fetchStoryState} = useStoryState()

    const timeoutRef = useRef(null)

    useEffect(() => {
        timeoutRef.current = setInterval(() => {
            fetch('/checkLogin', { method: "POST" })
                .then(res => res.json())
                .then(user => {
                    if (user.paypal_transaction.custom) {
                        clearInterval(timeoutRef.current)
                        fetchStoryState()
                        navigate('/music')
                        navigate(0)
                        localStorage.setItem('tracknotifications', '[]')
                        setLoggedIn(user)
                    }
                })
        }, 1000);
    }, [])

    return (
        <section id="content">
        <div className="content-wrap">
            <div className="container clearfix py-5 my-5">
                <div id="unlocking-jumbotron" class="p-5 mb-5 bg-body-tertiary rounded-3">
                <div class="container-fluid py-5 my-5">
                    <h1 class="display-5 fw-bold text-center">Unlocking Music...</h1>
                    <div class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                </div>
            </div>

            </div>
        </div>
    </section>
   


   
      
    )
}