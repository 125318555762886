
export default function TextDropdown({ markup }) {

  return (
    <>
     <a class="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
      data-bs-target="#flush-collapseOne" className="bi bi-plus-circle-fill"></a>
    
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item">
          <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body"><div dangerouslySetInnerHTML={{ __html: markup }} /></div>
          </div>
        </div>
      </div>
    </>
  );
};
