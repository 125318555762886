import { useEffect, useState } from "react"
import { useAudioManager } from "../../hooks/audioManager-hook";


export default function SoundButton({ type, path, trackName }) {
  const [isLoading, setIsLoading] = useState(true)
  const {trackToggled, isLoaded } = useAudioManager()

  useEffect(() => {
    if(isLoaded.length > 0){
      if(isLoaded.includes(path)){
        setIsLoading(false)
      }
    }
  }, [isLoaded])

  return (
    <>
      {isLoading
        ? <i className={`bi bi-circle-fill loader-circle ${type}`}>
          <span className={`spinner-border story-track ${type}`}></span>
        </i> :
        <i onClick={() => {trackToggled({ type, path, trackName, isPlaying: true}) }}
          className={`bi bi-play-circle-fill ${type}`} />
      }
    </>
  );
};