import {useLocation, useNavigate } from "react-router-dom";
import { useStoryState } from "../../hooks/storyState-hook";
import { useLoggedIn } from "../../hooks/loggedin-hook"
import "./menu.css";


const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { unlockedActs, trackNotifications } = useStoryState();
  const { loggedIn } = useLoggedIn()
  

  function closeNavbar() {
    if (document.querySelector('.navbar-collapse')
      .classList.contains('show')) {
      document.querySelector('.navbar-toggler').click()
    }
  }

  function renderedScenes(act, actNum) {

    return act.map((title, sceneNum) => {
      return (
        <li key={sceneNum}>
          <a
            className="dropdown-item"
            onClick={() => {
              closeNavbar()
              if (!location.pathname.endsWith(`/${actNum}/${sceneNum}`)) {
                navigate(`/story/${actNum}/${sceneNum}`)
              }
            }}
          >
            {title.split("-")[0]}
          </a>
          {sceneNum < act.length - 1 ? <div class="dropdown-divider"></div> : null}
        </li>
      );
    });
  }

  const renderedActs = unlockedActs?.map((act, i) => (
    <li key={i} className="nav-item pe-4 py-2 dropdown">
      <a
        id={"nav-link-" + i + 1}
        className="nav-link"
        href={!loggedIn?.email && location.pathname === "/" && "#get-started"} role="button"
        data-bs-toggle={act.length != 0 ? "dropdown" : ""}
        aria-expanded="false"
        onClick={() => {
          if (!loggedIn?.email) {
            closeNavbar()
          }
        }}
      >
        Act {i + 1} {act.length === 0 && <i class="bi bi-lock nav-link-lock-icon"></i>}
      </a>

      {act.length === 0 ? null :
        <ul id={`dropdown-menu-${i + 1}`} className="dropdown-menu">
          {renderedScenes(act, i + 1)}
        </ul>
      }
    </li>
  ));

  return (
    <>
      <li className="nav-item py-2 pe-4">
        <a
          className="nav-link"
          onClick={() => {
            navigate("/")
            navigate(0)
            closeNavbar()
            window.scrollTo(0, 0)
          }}
        >
          Home
        </a>
      </li>

      {renderedActs}

      <li
        className="nav-item position-relative py-2 pe-4">
        <a
          className="nav-link"
          onClick={() => {
            navigate("/music")
            closeNavbar()
            window.scrollTo(0, 0)
          }}
        >
          Music
          {loggedIn.email && trackNotifications?.length > 0 &&
            <i class="ms-1 bi bi-unlock nav-link-lock-icon">
              <sup id="new-unlocked-icon" className="bi bi-circle-fill numberCircle">
                <span style={{ padding: trackNotifications.length < 10 ? "0.25em" : "0em" }} id="new-unlocks">{trackNotifications?.length}</span>
              </sup>
            </i>
          }
        </a>

      </li>
    </>
  );
};

export default Menu;
