export default function Map() {
    

    return (
            <div id="map" class="modal fade"
             data-bs-backdrop="false" aria-hidden="true"  tabindex="-1">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                        <div class="modal-body">
                            <img style={{"minWidth":"0px"}} src="/images/map.jpg"></img>
                        </div>
                </div>
            </div>
    );
};
