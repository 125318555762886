import { useEffect, useState } from "react";
import { useLoggedIn } from "../../hooks/loggedin-hook"

export function AlbumTrack({ trackName, albumName, path,
  unlocked, handleToggledTrack, isLoading, tracksState, trackNotifications,
  setTrackNotifications, unlockedTracks }) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [showDownload, setShowDownload] = useState(false)
  const [cleared, setCleared] = useState(false)
  const { loggedIn } = useLoggedIn()


  

  // if track is unlocked set it unlocked
  useEffect(() => {
    if (!trackNotifications.includes(trackName)
      && unlockedTracks.includes(trackName)) {
      unlockedTracks.forEach(tn => tn === trackName && setCleared(true))
    }
  }, [unlockedTracks])

  useEffect(() => {
    if (trackNotifications.includes(trackName) && showDownload) {
      const filtered = trackNotifications.filter(tn => tn !== trackName)
      setTrackNotifications(filtered)
      setCleared(true)
    }
  }, [showDownload])

  function checkIsLoading() {
    return isLoading?.trackName === trackName
      && isLoading.isLoading
  }

  useEffect(() => {
    if(tracksState?.length > 0){
      tracksState.forEach(track => {
        if(track.trackName === trackName){
          setIsPlaying(track.isPlaying)
        }
      })
    }
    
  }, [tracksState])

  function returnLockOrButton(){
    if(!unlocked){
      return <i className="track-lock bi bi-lock"></i>
    } else if(unlocked && !cleared){
      return <i className="track-lock bi bi-unlock-fill"></i> 
    }
      else if(unlocked && cleared){ 
        return <i onClick={() => {
          if (!checkIsLoading()) {
            setIsPlaying(!isPlaying);
            handleToggledTrack({ trackName, albumName, path, isPlaying: !isPlaying })
          }
        }}
          className={(checkIsLoading() ? "spinner-border music-track " : "trackButton ")
            + (!checkIsLoading() ? isPlaying
              ?
              "bi bi-pause-circle-fill"
              : "bi bi-play-circle-fill"
              : null
            )}></i>
  }
}

  return (
    <span className="row align-items-center track-row mb-2"
      data-path={path}
      data-album={albumName}
      data-trackname={trackName}
      data-unlocked={unlocked}
    >



      <div className="col-2 ms-1" >

        {returnLockOrButton()}

      </div>

      <div className="col"
        onMouseEnter={() => loggedIn.email && setShowDownload(true)}
        onMouseLeave={() => loggedIn.email && setShowDownload(false)}>

        <div className="d-flex justify-content-between align-items-center">
          <div>
            {trackName}
          </div>

          <div>
            {loggedIn?.email && unlocked && showDownload
              && <a href={new URL(path).pathname} download>
                <i class="bi bi-cloud-arrow-down-fill"></i>
              </a>}
          </div>
        </div>

      </div>

    </span>
  )
}