import { useLayoutEffect, useState } from "react";
import { useStoryState } from "../../hooks/storyState-hook";
import { useLoggedIn } from "../../hooks/loggedin-hook"

import { useMusicAudioManager } from "../../hooks/musicAudioManager";
import { AlbumTrack } from "./AlbumTrack";
import StoryConfig from "../../config/storyConfig.json";
import "./album.css"

const BASE_AUDIO_PATH = StoryConfig.cdnEndpoint

export default function Album({ albumData, albumNum }) {
  const { unlockedTracks, trackNotifications, setTrackNotifications } = useStoryState()
  const [albumUnlocked, setAlbumUnlocked] = useState(false)
  const [allUnlocked, setAllUnlocked] = useState(false)
  const [showDownload, setShowDownload] = useState(false)
  const { handleToggledTrack, isLoading, tracksState } = useMusicAudioManager()
  const { loggedIn } = useLoggedIn()


  // unlock the album if atleast one track is unlocked
  useLayoutEffect(() => {

    isAlbumUnlocked()

  }, [unlockedTracks])


  function isAlbumUnlocked() {

    const { clickedUnlocks, paidTracks } = StoryConfig

    // if all tracks are unlocked
    if (allTracksUnlocked(albumData)) {
      setAlbumUnlocked(true)
    }

    // if a track has been clicked
    // for each track on the album
    albumData.tracks.forEach(({ trackName }) => {

      //user clicked on a paid track and user hasn't paid
      if (unlockedTracks.includes(trackName)
        && (paidTracks.includes(trackName)
          && loggedIn.paypal_transaction)) {


        setAlbumUnlocked(true)
      }
      //user clicks on click track
      else if (unlockedTracks.includes(trackName)
        && clickedUnlocks.includes(trackName)) {
        setAlbumUnlocked(true)
      }
    })
  }



  function allTracksUnlocked(albumData) {
    return albumData.tracks.every(function (el) {
      return unlockedTracks.includes(el.trackName)
    })
  }

  // set unlocked all to show the download album button
  useLayoutEffect(() => {
    const unlockedMap = albumData.tracks.map(({ trackName }) => trackIsUnlocked(trackName))
    setAllUnlocked(!unlockedMap.some(value => value === false))
  }, [unlockedTracks])

  function trackIsUnlocked(trackName) {
    return unlockedTracks.includes(trackName)
  }


  const renderedAlbum = albumData.tracks.map((track, i) => (
    <AlbumTrack key={track.trackName}
      trackName={track.trackName}
      albumName={albumData.albumName}
      path={BASE_AUDIO_PATH + '/' + track.path}
      isLoading={isLoading}
      tracksState={tracksState}
      handleToggledTrack={handleToggledTrack}
      trackNotifications={trackNotifications}
      setTrackNotifications={setTrackNotifications}
      unlockedTracks={unlockedTracks}
      unlocked={trackIsUnlocked(track.trackName)}
      data-set-unlocked={trackIsUnlocked(track.trackName)}
    />
  ))


  return (
    // album card 

    <article className={`portfolio-item col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 pf-fatal-fire mb-5 pf-album-${albumNum}`}>

      <div class="grid-inner">
        <div className="album-card card">

          <div class="portfolio-image center">

            {/* display un/locked image */}
            <img src={albumUnlocked
              ? albumData.unlockedImage
              : albumData.lockedImage} className="card-img-top" alt="..." />
          </div>

          <div className="card-body">

            {/* album detials */}
            <div className="row align-items-center justify-content-between mb-2 ms-0">

    

              <div className="col"
                onMouseEnter={() => loggedIn.email && setShowDownload(true)}
                onMouseLeave={() => loggedIn.email && setShowDownload(false)}>

                <div className="d-flex justify-content-between align-items-center">

                  <div >
                    <span className="album-name">{albumData.albumName}</span>
                  </div>

                  <div className="download-column">
                    {/* if all tracks are unlocked show the download album button */}
                    {allUnlocked && showDownload
                      ? <a href={`${BASE_AUDIO_PATH}/audio/archives/${albumData.albumName}.zip`} download><i class="ps-2 bi bi-cloud-arrow-down-fill"></i></a>
                      : null}
                  </div>

                </div>
              </div>
            </div>

            {/* Album Tracks */}
            <div className="container">
              {renderedAlbum}
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}

