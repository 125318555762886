import { FiVolume, FiVolume1, FiVolume2, FiVolumeX } from "react-icons/fi"

export default function VolumeControls({ setMuted, muted, setVolume, volume, theme="dark" }) {

    function renderIcon(volume) {
        const cName =`volumeIcon-${theme}`
        if (volume <= 0) {
            return <FiVolumeX className={cName} />
        }
        else if (volume > 0 && volume <= .2) {
            return <FiVolume className={cName} />
        }
        else if (volume > .2 && volume <= .5) {
            return <FiVolume1 className={cName} />
        }
        else if (volume > .5) {
            return <FiVolume2 className={cName} />
        }
    }


    return (
        <div id="story-volume-block" className="d-flex flex-row-reverse">
            <div>
                <input onInput={(e) => {
                    setVolume(e.target.value)
                }}
                    value={volume}
                    className='volumeSlider form-range'
                    type="range"
                    min="0"
                    step="0.01"
                    max="1" />
            </div>
            <div onClick={() => {
                muted ? setMuted(false) : setMuted(true)
            }}>
                {renderIcon(volume)}
            </div>
        </div>
    )
}

