import { useEffect, useState } from "react"
import { useAudioManager } from "../../hooks/audioManager-hook";

export default function AudioButton({ type, path, trackName }) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const { resetTrack, trackToggled, isLoaded } = useAudioManager()

  useEffect(() => {
    if(isLoaded.length > 0){
      if(isLoaded.includes(path)){
        setIsLoading(false)
      }
    }
  }, [isLoaded])

  useEffect(() => {
    if (resetTrack?.path
      && resetTrack?.path === path) {
      setIsPlaying(resetTrack.isPlaying)
    }
  }, [resetTrack])

  return (
    <>
      {isLoading
        ? <i className={`bi bi-circle-fill loader-circle ${type}`}>
          <span className={`spinner-border story-track ${type}`}></span>
        </i> :
        <i onClick={() => { setIsPlaying(!isPlaying); trackToggled({ type, path, trackName, isPlaying: !isPlaying }) }}
          className={isPlaying ? `bi bi-pause-circle-fill ${type}` : `bi bi-play-circle-fill ${type}`} />
      }
    </>
  );
};