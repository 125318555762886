import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export default function ResetPassword() {
    const [updated, setUpdated] = useState(false)
    const [verified, setVerified] = useState(null)
    const { token } = useParams()

    useEffect(() => {
        if (token) {
            //verify token
            fetch("/verifyToken",
                {
                    method: 'POST',
                    body: JSON.stringify({ token: token.toString() }),
                    headers: { 'Content-Type': 'application/json' }
                })
                .then(res => res.json())
                .then(({ verified }) => setVerified(verified))
        }
    }, [token])

    function handleSubmit(e) {

        // prevent default button behaviour
        e.preventDefault()

        // send new password to the server
        const password = e.target.password.value
        const confirmed = e.target.confirmed.value
        const body = JSON.stringify({ password, token })
        if (password === confirmed) {
            fetch('/updatePassword',
                {
                    method: 'POST',
                    body,
                    headers: { 'Content-Type': 'application/json' }
                }
            )
                .then(res => res.json())
                .then(({ updated }) => {
                    setUpdated(updated)
                })
        }
        else {
            alert('Passwords do not match')
        }
    }


    function renderContent() {
        if (verified === null) {
            return <div className='py-5 my-5'>
                <h1 className="center display-5  fw-bold"></h1>
            </div>
        }
        else if (verified === false) {
            return <div className='py-5 my-5'>
                <h1 className="center display-5 pb-5 mb-5 fw-bold">Reset token invalid or expired.</h1>
            </div>
        }
        else if (verified === true) {
            return <>
                <h1 className="center display-5 fw-bold">Update Password</h1>
                <p className="center">Create a new password.</p>
                {updated
                    ? window.location = '/'
                    :
                    <form className='form-auth pb-5 mb-5 text-center' onSubmit={handleSubmit}
                        rel='noreferrer'>
                        <input
                            autoComplete='off'
                            type='password'
                            // https://owasp.org/www-community/OWASP_Validation_Regex_Repository
                            // 8 to 64 character password requiring numbers and both lowercase and uppercase letters
                            pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$'
                            title='8 to 64 character password requiring numbers and both lowercase and uppercase letters.'
                            id='widget-reset-form-password'
                            name='password'
                            className='form-control form-control-sm not-dark required password mb-2'
                            placeholder='New Password'
                            required
                        />
                        <input
                            autoComplete='off'
                            type='password'
                            // https://owasp.org/www-community/OWASP_Validation_Regex_Repository
                            // 8 to 64 character password requiring numbers and both lowercase and uppercase letters
                            pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$'
                            title='8 to 64 character password requiring numbers and both lowercase and uppercase letters.'
                            id='widget-update-form-password'
                            name='confirmed'
                            className='form-control form-control-sm not-dark required password'
                            placeholder='Confirm Password'
                            required
                        />
                        <button type='submit' className='button button-border button-light button-rounded mb-5'>Update Password</button>
                    </form>
                }
            </>
        }
    }

    return (
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix py-5 my-5">
                    {renderContent()}
                </div>
            </div>
        </section>

    )
}



