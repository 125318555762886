import { useLocation } from "react-router-dom";
import "./footer.css"

export default function Footer() {
  const loc = useLocation();
  let color = "";
  // eslint-disable-next-line default-case
  switch (loc.pathname) {
    case "/music":
      color = "light";
      break;
    default:
      color = "dark";
  }

  return (
    <footer id="footer" className={color}>
      <div id="copyrights">
        <div className="container center clearfix">
          © Fatal Fire | All Rights Reserved. || <a href="/credits">Credits</a>
        </div>
      </div>
    </footer>
  );
};


