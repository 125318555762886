
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Login from "../auth/LoginForm"

export default function ActivateAccount() {
    const [activated, setActivated] = useState(null)
    const { token } = useParams()


    useEffect(() => {

        fetch('/activation',
            {
                method: 'POST',
                body: JSON.stringify({ token }),
                headers: { 'Content-Type': 'application/json' }
            }
        )
            .then(res => res.json())
            .then(json => setActivated(json.activated))
    }, [])


    function returnMessage() {

        switch (activated) {
            case null:
                return <div className='py-5 my-5'>
                    <h1 class="display-4"></h1>
                    <p class="lead py-5 my-5"></p>
                    <hr class="my-4" />
                </div>
            case true:
                return <div className='py-5 my-5'>
                    <h1 class="display-4">Account has been activated!</h1>
                    <p class="lead">Thank you for activating your account, please login to access the story!</p>
                   <Login/>
                    <hr class="my-4" />
                </div>
            case false:
                return <div className='py-5 my-5'><h1 class="display-4">Activation Error!</h1>
                    <p class="lead">Please email support@fatalfire.com for assistance.</p>
                    <hr class="my-4" />
                </div>
                default:
                    break
        }
    }



    return (
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix py-5 my-5">
                    <div class="jumbotron">
                        {returnMessage()}
                    </div>
                </div>
            </div>
        </section>

    )
}

