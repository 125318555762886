import { useEffect } from "react";

export default function Credits() {


  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
  }, [])



  return (
    <>
      <section id="slider" className="slider-element min-vh-100 dark include-header">
        <div className="slider-inner page-title-parallax" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/The-Goth-Supper-wVJ_1920.jpg)`, backgroundPosition: "50% 0" }}>
        </div>
      </section>
      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            <div className="fancy-title title-border-color">
              <h1>Credits</h1>
            </div>

            <blockquote>
              <b>Fatal Fire Overview</b>
              <br />
              —Words and {" "}
              <a href="https://youtu.be/TxuOqwieCZ8by" style={{ color: "#212529", textDecoration: "none" }} target="_blank" rel="noreferrer">music</a> by{" "}
              <a href="https://vjmanzo.com/" target="_blank" rel="noreferrer">
                V.J. Manzo
              </a>{" "}
              <br />
              —{" "}
              <a href="https://youtu.be/1c_d5aL2GG4" style={{ color: "#212529", textDecoration: "none" }} target="_blank" rel="noreferrer">Mixed</a> and produced by V.J. and {" "}
              <a href="https://walterkazmier.com/" target="_blank" rel="noreferrer">
                Walt Kazmier
              </a>, and mastered by <a href="http://mikepmastering.com/" target="_blank" rel="noreferrer">
                Mike Piacentini
              </a>
              <br />
              —Interactive platform developed by {" "}
              <a href="https://github.com/JakePelrah" target="_blank" rel="noreferrer">
                Jake Pelrah
              </a>
               , {" "}
              <a href="https://youtu.be/99b1LDWNMbY" style={{ color: "#212529", textDecoration: "none" }} target="_blank" rel="noreferrer"> V.J.</a>, and Gabe Camacho
              <br />
              <br />
              <b>Additional Performances and Compositions</b>
              <br />
              All music and sound design composed and performed by V.J. with the following exceptions:<br />
              —Music for "Light of My Life" &amp; "the Spark" composed by V.J. and Chris Manzo
              <br />
              —Bass on "the Spark, "We Lift Our Hands" performed by Chris and the bass solo on "Endlessly" composed &amp; performed by Chris
              <br />
              —Lead vocals on "You", "the Depths", and "Anacrusis" performed by Janine Schwarz
              <br />
              —Lead vocals on "Watch Me" &amp; "Recurrence" performed by Raf
              <br />
              —Lead vocals on "Endlessly" performed by Mr. Maph and Kria; ad lib vocals performed by David and Tiffany S.
              <br />
              —Lead vocals on "Echo Song" performed by Berzan Onen
              <br />
              —Lead vocals on "Absolution", "Alibis", and "Into Your Hands" performed by Claire and Milan; spoken vocals performed by Mauro
              <br />
              —Lead vocals on "the Answer" and <em>Song Special</em> version of "Into Your Hands" performed by
              Amy Faith Morley
              <br />
              —Lead vocals on the hymn "What Can Wash Away My Sin" performed by Gene &amp; Ron; composed by{" "}
              <a href="https://en.wikipedia.org/wiki/Robert_Lowry_(hymn_writer)" style={{ color: "#212529", textDecoration: "none" }} target="_blank" rel="noreferrer">
                Robert Lowry
              </a>
              <br />
              —Additional background vocals on "Knowing That You Love Me", "We Lift Our Hands", "You Are My Everything", "Endlessly", and "Hallelujah" performed by David, Tiffany, and Raf
              <br />
              —Vocal color on "We Lift Our Hands" and "You Are My Everything" performed by Katie B.
              <br />
              —"Night and Dreams" (Nacht und Träume) composed by <a href="https://en.wikipedia.org/wiki/Franz_Schubert" style={{ color: "#212529", textDecoration: "none" }} target="_blank" rel="noreferrer">
                Franz Schubert
              </a>
              <br />
              —"The Goth Supper" artwork by{" "}
              <a href="https://www.instagram.com/shayaninzi/" target="_blank" rel="noreferrer">
                Shayan Inzi
              </a>
              <br />
              <br />
              <br />
              More information on the production of Fatal Fire is available {" "}
              <a href="http://vjmanzo.com/ff" target="_blank" rel="noreferrer">
                here
              </a>.
              <br />
              <br />
              <br />
              All site content © Fatal Fire | All Rights Reserved. Unauthorized use prohibited. Clear Blue Media LLC.
            </blockquote>
          </div>
        </div>
      </section>
    </>
  );
};


