import { useState } from "react"
import { useStoryState } from "../../hooks/storyState-hook";
import { Modal } from "bootstrap";


export default function EditModal({ sceneData, setClose }) {
    const { editNote, deleteHighlight } = useStoryState();

    function onSaveEdit() {

        const noteText = document.getElementById('text-area').value

        if (noteText) {
            sceneData.datetime = new Date().toLocaleString()
            sceneData.noteText = noteText
            editNote(sceneData)
        }
    }


    function confirmDelete() {

        const msgModal = document.getElementById('msg-modal')
        const modalBody = msgModal.querySelector('.modal-body')

        const myModal = new Modal(msgModal)
        
        modalBody.innerText = 'Deleted note CANNOT be recovered!'
        
        myModal.show()

        const modalFooter = msgModal.querySelector('.modal-footer')
        const delButton = document.createElement('button')
        delButton.className = "btn trash-btn"

        delButton.innerText = "DELETE"
        
        delButton.onclick = (e) => {
            deleteHighlight([sceneData.id])
            myModal.hide()
            setClose(true)
        }
        
        modalFooter.innerHTML = ''
        modalFooter.innerHTML = `<button id="dismiss-btn" type="button" class="btn edit-btn" 
        data-bs-dismiss="modal">Close</button>`
        modalFooter.appendChild(delButton)
    }




    return (
            <div id='edit-modal' class="modal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-body">
                            <textarea id="text-area" class="form-control" rows="3">
                                {sceneData?.noteText}
                            </textarea>
                        </div>
                        <div class="modal-footer justify-content-center">
                            <button onClick={onSaveEdit}
                                className="btn edit-btn"
                                data-bs-dismiss="modal"
                                id="save-btn" >
                                Save
                            </button>

                            <button onClick={confirmDelete}
                                className="btn trash-btn">
                                <i class="bi bi-trash3"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    )
}
